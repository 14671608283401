import { CssBaseline, ThemeProvider, colors, createTheme } from "@mui/material"
import RootLayout from "./Layouts/RootLayout"
import Session from "./features/auth/Session"
import FaqPage from "./pages/Faq-page"
import Home from "./pages/Home"
import PrivacyPage from "./pages/Privacy-policy"
import TermsPage from "./pages/Terms-and-conditions"

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { useEffect, useState } from "react"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="terms" element={<TermsPage />} />
      <Route path="faq" element={<FaqPage />} />
      <Route path="delete" element={<Session />} />
    </Route>,
  ),
)

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#224E3F",
      dark: colors.teal[900],
      light: "#E6DAF2",
    },
    secondary: {
      main: "#DAF2EA",
      light: "#ffffff",
      dark: "#9887A8",
    },
  },
  typography: {
    body2: {
      fontWeight: "bold",
    },
  },
})

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#224E3F",
      dark: colors.teal[900],
      light: "#5FA189",
    },
    secondary: {
      main: "#2F4239",
      light: "#4D6659",
      dark: "#1C241F",
    },
    background: {
      default: "#121212",
      paper: "#1E1E1E",
    },
    text: {
      primary: "#ffffff",
      secondary: "#B0BEC5",
    },
  },
  typography: {
    body2: {
      fontWeight: "bold",
    },
  },
})
function App() {
  const [theme, setTheme] = useState(lightTheme)

  useEffect(() => {
    // Detect system theme preference
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches
    setTheme(prefersDarkMode ? darkTheme : lightTheme)

    // Listen for changes in system theme preference
    const themeChangeListener = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? darkTheme : lightTheme)
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", themeChangeListener)

    // Clean up listener when component unmounts
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", themeChangeListener)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
